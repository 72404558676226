import i18n from "./i18n";
import Vue from "vue";
import { ToastPlugin } from "bootstrap-vue";

Vue.use(ToastPlugin);
const vm = new Vue();

const BASE_PREFIX = "nb_";
const possibleLangs = {
  en: "en-US",
  zh_CN: "zh-Hans-CN",
  pt_BR: "pt-br",
};

export default {
  /**
   * Converts an array into a json string
   *
   * @param {*} array
   */
  arrayToJsonString(array = []) {
    let jsonString = "{";

    array.forEach((item) => {
      jsonString += item;
      jsonString += ",";
    });

    jsonString = jsonString.substring(0, jsonString.length - 1) + "}";

    return jsonString;
  },

  /**
   * Extract error messages from exception
   *
   * @param {*} error
   */
  extractErrorMessages(error) {
    let errorMessages = [];

    if (
      Object.prototype.hasOwnProperty.call(error, "response") &&
      Object.prototype.hasOwnProperty.call(error.response, "data") &&
      Object.prototype.hasOwnProperty.call(error.response.data, "messages")
    ) {
      error.response.data.messages.forEach((element) => {
        if (typeof element == "object") {
          Object.keys(element).forEach((elementKey) => {
            if (Array.isArray(element[elementKey])) {
              element[elementKey].forEach((erroMessage) => {
                if (elementKey === "base") {
                  errorMessages.push(erroMessage);
                } else {
                  errorMessages.push(elementKey + " " + erroMessage);
                }
              });
            } else if (
              typeof element[elementKey] == "string" &&
              elementKey != "codigo_retorno" &&
              elementKey != "descricao_retorno"
            ) {
              errorMessages.push(element[elementKey]);
            }
          });
        } else if (typeof element == "string") {
          errorMessages.push(element);
        }
      });
    } else {
      errorMessages.push(error.message);
    }

    return errorMessages;
  },

  /**
   * Load an unsaved draft by its name if exists.
   * @param {string} name - Work name.
   */
  loadUnsavedWork(name) {
    const unsaved = localStorage.getItem(`${BASE_PREFIX}${name}`);

    if (unsaved) {
      return JSON.parse(unsaved);
    }
    return null;
  },

  /**
   * Save an unsaved draft by its name.
   * @param {string} name - Unsaved work name.
   */
  saveUnsavedWork(name, value) {
    const valueAsString = JSON.stringify({
      data: value,
      updatedAt: Date.now(),
    });
    localStorage.setItem(`${BASE_PREFIX}${name}`, valueAsString);
  },

  /**
   * Delete an unsaved draft by its name.
   * @param {string} name - Unsaved work name.
   */
  deleteUnsavedWork(name) {
    localStorage.removeItem(`${BASE_PREFIX}${name}`);
  },

  /**
   * Check for deep equality between objects.
   * @param {string} object1 - First object to compare.
   * @param {string} object2 - Second object to compare.
   */
  isDeepEqual(object1, object2) {
    const object1Keys = Object.keys(object1);
    const object2Keys = Object.keys(object2);

    if (object1Keys.length !== object2Keys.length) {
      return false;
    }

    const isObject = (value) => value != null && typeof value === "object";

    for (const key of object1Keys) {
      const value1 = object1[key];
      const value2 = object2[key];
      const areObjects = isObject(value1) && isObject(value2);

      if (
        (areObjects && !this.isDeepEqual(value1, value2)) ||
        (!areObjects && value1 !== value2)
      ) {
        return false;
      }
    }
    return true;
  },

  formatShortDate(date, options) {
    const locale = possibleLangs[i18n.locale];
    const day = new Date(date);

    return day.toLocaleDateString(locale, options);
  },
  formatShortTime(date, options) {
    const locale = possibleLangs[i18n.locale];
    const day = new Date(date);

    return day.toLocaleTimeString(locale, options);
  },

  //Session control
  getPlatform(isPrefix) {
    const platformPrefix = isPrefix === "prefix";
    let platforms = {
      total: "total",
      nobordist: platformPrefix ? "nb" : "nobordist",
      asia: "asia",
      ecross: "e-cross",
      anjun: "anjun",
      tiendamia: "tiendamia",
      mirakl: "mirakl",
      skypostal: "skypostal",
      jt: "jt",
      correios: "correios",
      shopee: "shopee",
      loggi: "loggi",
    };

    const currentPlatform = localStorage.getItem("platform");
    if (currentPlatform in platforms) {
      return platforms[currentPlatform];
    }

    if (window.location.pathname === "/nb-login") {
      return platforms.nobordist;
    }
    if (window.location.pathname === "/total-login") {
      return platforms.total;
    }
    if (window.location.pathname === "/asia-login") {
      return platforms.asia;
    }
    if (window.location.pathname === "/e-cross-login") {
      return platforms.ecross;
    }
    if (window.location.pathname === "/anjun-login") {
      return platforms.anjun;
    }
    if (window.location.pathname === "/tiendamia-login") {
      return platforms.tiendamia;
    }
    if (window.location.pathname === "/mirakl-login") {
      return platforms.mirakl;
    }
    if (window.location.pathname === "/skypostal-login") {
      return platforms.skypostal;
    }
    if (window.location.pathname === "/jt-login") {
      return platforms.jt;
    }
    if (window.location.pathname === "/correios-login") {
      return platforms.correios;
    }
    if (window.location.pathname === "/shopee-login") {
      return platforms.shopee;
    }
    if (window.location.pathname === "/loggi-login") {
      return platforms.loggi;
    }

    const url = window.location.host;
    const urlPlatform = url.split(".")[0];

    if (urlPlatform.toLowerCase().includes("total")) {
      return platforms.total;
    }
    if (urlPlatform.toLowerCase().includes("asia")) {
      return platforms.asia;
    }
    if (url.toLowerCase().includes("e-cross")) {
      return platforms.ecross;
    }
    if (url.toLowerCase().includes("anjun")) {
      return platforms.anjun;
    }
    if (
      url.toLowerCase().includes("tiendamia") ||
      url.toLowerCase().includes("laas")
    ) {
      return platforms.tiendamia;
    }
    if (url.toLowerCase().includes("mirakl")) {
      return platforms.mirakl;
    }
    if (url.toLowerCase().includes("skypostal")) {
      return platforms.skypostal;
    }
    if (url.toLowerCase().includes("jt")) {
      return platforms.jt;
    }
    if (url.toLowerCase().includes("correios")) {
      return platforms.correios;
    }
    if (url.toLowerCase().includes("shopee")) {
      return platforms.shopee;
    }
    if (url.toLowerCase().includes("loggi")) {
      return platforms.loggi;
    }

    return platforms.nobordist;
  },

  getUser() {
    const platform = this.getPlatform();
    const user = JSON.parse(localStorage.getItem(`${platform}-user`));
    return user;
  },

  /*
  setUser(userData) {
    const platform = this.getPlatform();
    return localStorage.setItem(`${platform}-user`, userData);
  },

  setLoggedInUser (userData) {
    const platform = this.getPlatform();
    return localStorage.setItem(`${platform}-user`, userData);
  },
  */
  toast(message, variant, time) {
    const hash = Math.random().toString(36).substring(7);
    const crtHtml = vm.$createElement;
    const $closeButton = crtHtml("i", {
      class: "fas fa-times",
      on: { click: () => vm.$bvToast.hide(hash) },
      style: {
        float: "right",
        cursor: "pointer",
        padding: "6px",
        height: "28px",
        top: "calc(50% - 14px)",
        right: "3px",
        position: "absolute",
      },
    });
    vm.$bvToast.toast([message, $closeButton], {
      id: hash,
      toaster: "b-toaster-bottom-center",
      solid: true,
      variant: variant ? variant : "warning",
      autoHideDelay: time ? time : 2000,
      noCloseButton: true,
    });
  },
  closeModal(modalId) {
    if (!modalId) {
      document.querySelector("[data-dismiss=modal]").click();
    }
    document.getElementById(`${modalId}-close`).click();
  },
  closeAllModals() {
    // Find all modals
    const openedModals = document.querySelectorAll(".modal.show");
    openedModals.forEach((modal) => {
      modal.classList.remove("show");
      modal.style.display = "none";
      document.body.classList.remove("modal-open");
      var backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
    });
  },
  openModal(modalId) {
    document.getElementById(`${modalId}-open`).click();
  },
  inputRules(newValue, rules, required) {
    let internalErrors = [];
    if (required) {
      internalErrors.length = 0;
      if (newValue == "") {
        internalErrors.push("Can't be blank");
        return internalErrors;
      } else {
        internalErrors.push("Refresh");
        internalErrors.pop();
      }
    }
    if (rules) {
      const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let rulesArray = rules.split("|");
      const isValid = EMAIL_REGEX.test(newValue);
      for (let rule in rulesArray) {
        let ruleDetails = rulesArray[rule].split(":");
        switch (ruleDetails[0]) {
          case "email":
            internalErrors.length = 0;
            if (!isValid) {
              internalErrors.push(i18n.t("errorMessages.invalidEmail"));
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;

          case "length":
            internalErrors.length = 0;
            if (newValue.length < ruleDetails[1]) {
              internalErrors.push(
                "Can't be lower than " + ruleDetails[1] + " digits"
              );
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          case "minLength":
            internalErrors.length = 0;
            if (newValue.length < ruleDetails[1]) {
              internalErrors.push(
                "Can't be lower than " + ruleDetails[1] + " digits"
              );
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          case "maxLength":
            internalErrors.length = 0;
            if (newValue.length > ruleDetails[1]) {
              internalErrors.push(
                "Can't be more than " + ruleDetails[1] + " digits"
              );
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          case "notLength":
            internalErrors.length = 0;
            if (newValue.length == ruleDetails[1]) {
              internalErrors.push("Can't be " + ruleDetails[1] + " digits");
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;

          case "required":
            internalErrors.length = 0;
            if (newValue == "") {
              internalErrors.push("Can't be blank");
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          /* more rules example
          case "minVal":
            internalErrors.length = 0;
            if (Number(newValue) < parseFloat(ruleDetails[1])) {
              internalErrors.push("Can't be lower than " + ruleDetails[1]);
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;

          case "maxVal":
            internalErrors.length = 0;
            if (Number(newValue) > parseFloat(ruleDetails[1])) {
              internalErrors.push("Can't be more than " + ruleDetails[1]);
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          
          case "number":
            this.error.length = 0
            if (newValue != "") {
              let regex = /^[0-9]*\.?[0-9]*$/
              if (!regex.test(newValue.toLowerCase())) {
                internalErrors.push('is not a number')
                return internalErrors;
              } else {
                internalErrors.push('Refresh')
                internalErrors.pop()
              }
            }
            break;

          case "numberValue":
            this.error.length = 0
            if (newValue != "") {
              let regex = /^[0-9]*\.?[0-9]*$/
              if (!regex.test(newValue.toLowerCase())) {
                internalErrors.push('is not a number (use dot insted comma)')
                return internalErrors;
              } else {
                internalErrors.push('Refresh')
                internalErrors.pop()
              }
            }
            break;

          
           */
        }
      }
    }
    return internalErrors;
  },
};
