<template>
  <div
    class="nb-badge d-flex justify-content-center"
    :class="`nb-badge-${this.type}`"
  >
    <i
      v-if="icon"
      class="alert-icon"
      :class="`${this.icon} text-${this.type}`"
    ></i>
    <span>
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "NbBadge",
  props: {
    text: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
    icon: {
      type: String,
      require: false,
    },
  },
};
</script>

<style scoped>
.nb-badge {
  padding: 0.25rem 0.5rem 0.1rem 0.5rem;
  border-radius: 0.25rem;

  text-align: center;
  font: normal normal bold 14px/20px Nunito Sans;
  letter-spacing: 0px;
  width: fit-content;
}
/* .nb-badge:hover > span {
  text-decoration: underline;
} */
.nb-badge-success {
  color: var(--success);
  background: var(--success-10);
  border: 1px solid var(--success);
}
.nb-badge-attention,
.nb-badge-warning {
  color: var(--black);
  background: var(--attention-10);
  border: 1px solid var(--attention);
}
.nb-badge-error,
.nb-badge-danger {
  color: var(--error);
  background: var(--error-10);
  border: 1px solid var(--error);
}
.alert-icon {
  vertical-align: middle;
  height: 1.06rem;
  margin-top: 0.2rem;
  margin-right: 0.68rem;
}
</style>
